@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900;6..12,1000&display=swap");

/* html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Nunito Sans", sans-serif; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  height: 50vh;
  overflow: auto;
}

.css-1w1gdci {
  height: 88vh !important;
}

header,
main {
  background-color: #f3f4f6;
}

.min-h-screen {
  min-height: 100vh;
}

.ml-auto {
  margin-left: auto;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-red-100 {
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity));
}

.text-red-200 {
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity));
}

.text-red-300 {
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity));
}

.text-red-400 {
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity));
}

.text-red-500 {
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity));
}

.text-red-600 {
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity));
}

.text-red-700 {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity));
}

.text-red-800 {
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity));
}

.text-red-900 {
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity));
}

.fill-current {
  fill: currentColor;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

.text-base {
  font-size: 1rem;
}

.text-gray-800 {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.font-light {
  font-weight: 390;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.space-y-4> :not(template)~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1rem * var(--space-y-reverse));
}

.md\:flex-row {
  flex-direction: row;
}

.md\:justify-between {
  justify-content: space-between;
}

.md\:items-center {
  align-items: center;
}

.md\:items-start {
  align-items: flex-start;
}

.cursor-pointer {
  cursor: pointer;
}

.p-2 {
  padding: 0.5rem;
}

.items-center {
  align-items: center;
}

.text-gray-100 {
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity));
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.text-sm {
  font-size: 0.875rem;
}

.uppercase {
  text-transform: uppercase;
}

.w-6 {
  width: 1.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.m-4 {
  margin: 1rem;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.w-full {
  width: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.md\:w-3\/6 {
  width: 50%;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.md\:px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.space-y-4> :not(template)~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1rem * var(--space-y-reverse));
}

.overflow-y-auto {
  overflow-y: auto;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

.text-left {
  text-align: left;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.text-2xl {
  font-size: 1.5rem;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.cursor-pointer {
  cursor: pointer;
}

/* .text-gray-100 {
  color: #f7fafc;
} */

.c-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.cancelIcon {
  cursor: pointer;
  display: inline-block;
}

.cancelSvg {
  stroke-width: 2;
  transition: stroke 0.3s ease;
}

.cancelIcon:hover .cancelSvg {
  stroke: red;
}

.c-py-1 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.h-full {
  height: 100%;
}

.c-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.hidden {
  display: none;
}

.opacity-50 {
  opacity: 0.5;
}

.bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.bg-red-200 {
  background-color: #fed7d7;
}

.top-0 {
  top: 0;
}

.z-30 {
  z-index: 30;
}

.left-0 {
  left: 0;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.w-full {
  width: 100%;
}

.bg-green-200 {
  background-color: #c6f6d5;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.w-full {
  width: 100%;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.w-full {
  width: 100%;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.flex {
  display: flex;
}

.justify-start {
  justify-content: flex-start;
}

.flex-col {
  flex-direction: column;
}

.md\:pb-6 {
  padding-bottom: 1.5rem;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.space-y-1> :not(template)~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--space-y-reverse));
}

.w-full {
  width: 100%;
}

.pb-4 {
  padding-bottom: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}


/* React Toast custom css start here */

/* toastStyles.css */

.Toastify__toast-container {
  width: auto !important;
  /* Set the width to auto to adjust dynamically based on content */
  max-width: 100%;
  /* Optionally, set a maximum width */
}

.Toastify__toast {
  white-space: nowrap !important;
  /* Prevent text wrapping */
  overflow: hidden !important;
  /* Hide overflow */
  text-overflow: ellipsis !important;
  /* Add ellipsis for overflow text */
}

.Toastify__toast-body {
  color: #fff !important;
  /* Set text color to white */
}

.Toastify__toast-icon svg {
  fill: #fff !important;
  /* Set success icon color to white */
}

.Toastify__toast-icon--error svg {
  fill: #fff;
  /* Set error icon color to white */
}

.Toastify__close-button {
  color: #fff;
  /* Set cancel icon color to white */
  margin-top: 10px;
  /* Adjust margin to align cancel icon with text */
}

.Toastify__close-button:hover {
  color: red;
}

.toast-success {
  background-color: #4caf50 !important;
  /* Green background */
  color: #fff !important;
  /* White text */
}

.toast-error {
  background-color: #f44336 !important;
  /* Red background */
  color: #fff !important;
  /* White text */
}

.toast-info {
  background-color: #2196f3 !important;
  /* Blue background */
  color: #fff !important;
  /* White text */
}

.toast-warning {
  background-color: #ff9800 !important;
  /* Orange background */
  color: #fff !important;
  /* White text */
}

.toast-update {
  background-color: #ffc107 !important;
  /* Yellow background */
  color: #000 !important;
  /* Black text */
}

.toast-custom {
  background-color: #9c27b0 !important;
  /* Purple background */
  color: #fff !important;
  /* White text */
}

/* React Toast custom css end here */


/* custom button css start here */

.btn-rounded {
  border-radius: 10rem !important;
  background: #303031 !important;
  box-shadow: 0 4px 9px -4px rgba(0,0,0,.35);
  text-transform: uppercase;
  vertical-align: bottom;
  border: 2px solid #303031 !important;
  transition: all 0.2s ease-in-out; /* Added transition property */
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
}

.btn-rounded:hover, .btn-dark:active, .btn-dark:focus {
  box-shadow: 0 4px 18px -2px rgba(0,0,0,.7);
  transition: all 0.2s ease-in-out; /* Added transition property */
  color: #fff !important;
}

/* custom button css end here */


/* Dashboard card css start here */
.card {
  border: none; /* Remove default card border */
  border-radius: 1rem; /* Rounded corners */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s ease-in-out; /* Smooth hover animation */
}

.card:hover {
  transform: translateY(-3px); /* Slight lift on hover */
}

.card-body {
  padding: 1.5rem 2rem; /* Adjust padding for better spacing */
}

.card-text {
  font-size: 1.2rem; /* Slightly larger text for readability */
  line-height: 1.5; /* Improved line spacing */
  color: #999; /* More muted text color for contrast */
}

.card-text .material-icons { /* Assuming Material Icons usage */
  color: inherit; /* Inherit text color for consistency */
}

.card-title {
  font-size: 2rem; /* Larger title for emphasis */
  font-weight: bold;
}
/* Dashboard card css end here */



/* Custom image avatar start here  */

.avatar-tiny {
  width: 24px;
  height: 24px;
  font-size: 12px;
}

.avatar-small {
  width: 36px;
  height: 36px;
  font-size: 18px;
}

.avatar-medium {
  width: 44px;
  height: 44px;
  font-size: 24px;
}

.avatar-large {
  width: 72px;
  height: 72px;
  font-size: 36px;
}

.avatar-big {
  width: 96px;
  height: 96px;
  font-size: 48px;
}

/* Custom image avatar end here  */

/* Custom navbar User avatar start here  */

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-item:active {
  color: black !important;
  text-decoration: none !important;
  background-color: #f8f9fa !important;
}


/* Define keyframes for a mind-blowing animation */
@keyframes menuSlideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px) scale(0.95);
  }

  80% {
    transform: translateY(10px) scale(1.02);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes menuSlideOut {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  20% {
    transform: translateY(10px) scale(1.02);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px) scale(0.95);
  }
}

/* Apply the animations to the menu */
.animated-dropdown-menu {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  transform-origin: top right;
}

.dropdown-menu.show {
  animation-name: menuSlideIn;
}

.dropdown-menu.hide {
  animation-name: menuSlideOut;
}

/* Custom navbar User avatar end here  */

/* Custom file upload start here  */

.file-btn {
  width: 330px;
  height: 65px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: #000;
  background-color: #fff;
  border: 2px dashed #000;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file-btn:hover {
  color: blue;
  background-color: #fff;
  border: 2px dashed blue;
}

.file-btn:disabled {
  color: #131111;
  border: 2px dashed #131111;

  background-color: #fff;
}

.file-btn span {
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #f1efff;
}

.file-card {
  width: 500px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #000;
  background-color: #fff;
  border: 1px solid rgba(5, 5, 9, 0.281);
  border-radius: 6px;
  padding: 8px 15px;
}

.file-info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 15px;
}

.file-info h6 {
  flex: 1;
  font-size: 13px;
  font-weight: 400;
}

.progress-bg-upload {
  width: 100%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.076);
  border-radius: 8px;
  margin-top: 8px;
}

.progress-rate-upload {
  width: 0%;
  height: 5px;
  background-color: green;
  border-radius: 8px;
  transition: width 0.5s ease;
}

.icon {
  font-size: 30px;
  color: blue;
}

.close-icon {
  font-size: 25px;
  cursor: pointer;
  color: gray;
  transition: all 0.3s ease-in-out;
}

.close-icon:hover {
  font-size: 27px;
  cursor: pointer;
  color: red;
}

.file-info button,
.check-circle {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: green;
  font-weight: bold;
  background-color: #f1efff;
  border: none;
  border-radius: 30px;
}

.upload-btn {
  width: 330px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: rgb(48, 48, 49);
  border: none;
  border-radius: 9999px;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
}

/* Custom file upload start end here  */

/* Custom deadline component css start here  */

.deadline-limit {
  font-weight: bold;
  font-size: 16px;
}

.deadline-limit.low {
  color: green;
}

.deadline-limit.medium {
  color: orange;
}

.deadline-limit.high {
  color: red;
}

.deadline-limit-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing between deadline text and progress bar */
}

.deadline-limit {
  font-weight: bold;
}

.progress-bar-container {
  width: 100px; /* Adjust width of progress bar */
  height: 5px; /* Adjust height of progress bar */
  background-color: #ddd;
  border-radius: 2px; /* Add rounded corners */
}

.progress-barC {
  height: 100%; /* Fill entire height of container */
  background-color: #007bff; /* Adjust progress bar color */
  transition: width 0.5s ease-in-out; /* Add smooth transition for progress bar animation */
}

.deadline-limit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.deadline-limit {
  font-size: 14px;
  margin-bottom: 10px;
  transition: color 0.5s;
}

.low {
  color: green;
}

.medium {
  color: orange;
}

.high {
  color: red;
}

.progress-bar-container {
  width: 100%;
  height: 5px;
  background-color: lightgrey;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  position: relative;
}

/* .progress-bar {
  height: 100%;
  background-color: #76c7c0;
  border-radius: 10px 0 0 10px;
  transition: width 0.5s ease-in-out, background-color 0.5s;
} */

.progress-bar-low-c {
  height: 100%;
  background-color: green;
  border-radius: 10px 0 0 10px;
  transition: width 0.5s ease-in-out, background-color 0.5s;
}

.progress-bar-medium-c {
  height: 100%;
  background-color: orange;
  border-radius: 10px 0 0 10px;
  transition: width 0.5s ease-in-out, background-color 0.5s;
}

.progress-bar-high-c {
  height: 100%;
  background-color: red;
  border-radius: 10px 0 0 10px;
  transition: width 0.5s ease-in-out, background-color 0.5s;
}

.progress-bar.remaining {
  background-color: #e0e0e0;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: width 0.5s ease-in-out, background-color 0.5s;
}



/* Custom deadline component css end here  */
