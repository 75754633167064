.tableContainer {
    margin-top: 10px;
    max-height: 500px;
}

.modalContainer {
    position: absolute;
    width: 400px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
